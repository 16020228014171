import * as React from "react"
import { useState } from "react"
import CircleOrange from "../assets/image/orange-circle.svg"
import SearchOrange from "../assets/image/orange-search.svg"

import Card from "../components/Card"
import Layout from "../components/layout"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"

const BlogsIndex = ({ pageContext }) => {
  const { key } = pageContext

  const tag = require("../assets/data/tags.json")
  const categories = require("../assets/data/categories.json")
  const blogPosts = require("../assets/data/blogsContent.json")

  const [searchTerm, _setSearchTerm] = useState(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("searchTerm")) ||
      ""
  )
  const setSearchTerm = keywords => {
    _setSearchTerm(keywords)
  }

  const [selectedTags, setSelectedTags] = useState([])
  const toggleTag = tag => {
    const tagIndex = selectedTags.indexOf(tag)
    if (tagIndex > -1) setSelectedTags(selectedTags.filter(e => e != tag))
    else setSelectedTags([...selectedTags, tag])
  }

  const [selectedCategory, setSelectedCategory] = useState("")

  const visibleBlogPosts = blogPosts.filter(
    post =>
      // check search term
      (!searchTerm ||
        JSON.stringify(post)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      // check category
      (!selectedCategory || post.blog_category.id === selectedCategory) &&
      // check tags
      (!selectedTags.length || selectedTags.find(tag => post.tag.includes(tag)))
  )
  function mycard(val) {
    return (
      <Card
        url={val.url}
        img={val.thumbnail?.formats.medium.url}
        category={val.blog_category.name}
        title={val.title}
        description={val.content[0]?.content}
        author_name={val.author.name}
        author_thumbnail={
          val.author.thumbnail?.formats?.thumbnail.url ||
          val.author.thumbnail?.url
        }
        published={val.published_at}
      />
    )
  }

  return (
    <MultiLangBody _key={key}>
      <Layout>
        <div className="blog pt-40 content mx-auto">
          <div className="max-w-max mx-auto relative">
            <img src={CircleOrange} className="absolute -top-10 -left-16" />
            <p className="green-dark text-4xl font-semibold">
              <MultiLangField name={"blogs"}>Blogs</MultiLangField>
            </p>
          </div>
          <div className="py-12 pt-6">
            <div className="flex flex-col lg:flex-row items-start w-full">
              <div className="blog-left my-6 flex flex-row items-start justify-end md:justify-between lg:flex-col lg:justify-start">
                <div className="relative bg-white border rounded-lg p-5 text-sm ml-5 mr-5 md:ml-6 lg:ml-0 w-full md:w-1/4 lg:w-full">
                  <input
                    type="text"
                    className="outline"
                    onChange={e => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    value={searchTerm}
                  />
                  <img src={SearchOrange} className="absolute top-5 right-4" />
                </div>
                <div className="lg:w-full lg:my-10 text-md text w-1/4 hidden md:block">
                  <p className="font-semibold">
                    <MultiLangField name={"categories"}>
                      Categories
                    </MultiLangField>
                  </p>
                  <div className="my-1 lg:my-0 overflow-auto scroll">
                    <ul className="my-3">
                      {categories.map(category => {
                        const isSelected = selectedCategory === category.id
                        return (
                          <li
                            onClick={() =>
                              setSelectedCategory(isSelected ? "" : category.id)
                            }
                            className={
                              (isSelected && "nav-link-active") +
                              " cursor-pointer"
                            }
                          >
                            {category.name}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="md:w-1/4 lg:w-full hidden md:block">
                  <p className="font-semibold mb-3">
                    <MultiLangField name={"popular-tags"}>
                      Popular Tags
                    </MultiLangField>
                  </p>
                  <div className="lg:w-full flex flex-wrap overflow-auto scroll">
                    {tag.map(tag => {
                      return (
                        <div
                          className={
                            "bg-green-light2 rounded-full py-2 px-3 text-sm blue-dark2 tag m-1" +
                            (selectedTags.includes(tag) ? " active" : "")
                          }
                          onClick={() => toggleTag(tag)}
                        >
                          {tag}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="w-full flex p-4 lg:pl-10 flex-wrap justify-center grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3">
                {visibleBlogPosts.map(mycard)}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </MultiLangBody>
  )
}

export default BlogsIndex
